import React, { useState } from 'react';

// Styles
import {
  IconInfo,
  ToolTipWrap,
  ToolTipTitle,
  ToolTipInner,
  ToolTipScore,
  ToolTipNum,
  InfoChar,
  InfoWrap,
} from './InfoToolTip.styles';

// Icons
import { ReactComponent as Info } from './assets/i.svg';

const InfoToolTip: React.FC<any> = ({ data }) => {
  const [isHover, setHover] = useState(false);
  const handleHover = (val: boolean) => {
    setHover(val);
  };
  
  return (
    <InfoWrap isHover={isHover}>
      <ToolTipWrap isShow={isHover} onMouseLeave={() => handleHover(false)}>
        <ToolTipTitle>НЕЙРО-БАЛЛЫ</ToolTipTitle>
        <ToolTipInner>
          <ToolTipScore color="#4BFF57">SPATIAL ABILITIES</ToolTipScore>
          <ToolTipNum color="#4BFF57">{data.spatial_abilities || 0}</ToolTipNum>
        </ToolTipInner>
        <ToolTipInner>
          <ToolTipScore color="#FEC600">HAND-EYE COORDINATION</ToolTipScore>
          <ToolTipNum color="#FEC600">{data.hand_eye_coordination || 0}</ToolTipNum>
        </ToolTipInner>
        <ToolTipInner>
          <ToolTipScore color="#AE7FFF">ATEENTION EFFICIENCY</ToolTipScore>
          <ToolTipNum color="#AE7FFF">{data.attention_efficiency || 0}</ToolTipNum>
        </ToolTipInner>
        <ToolTipInner>
          <ToolTipScore color="#FC6A37">WORKING MEMORY</ToolTipScore>
          <ToolTipNum color="#FC6A37">{data.working_memory || 0}</ToolTipNum>
        </ToolTipInner>
      </ToolTipWrap>
      <IconInfo onMouseEnter={() => handleHover(true)} isHover={isHover}>
        <InfoChar>
          <Info />
        </InfoChar>
      </IconInfo>
    </InfoWrap>
  );
};

export default InfoToolTip;
