import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { Row, Cell, Arrow } from '../../Tabel.styles';
import ArrowRight from '@assets/img/icon/arrow-right-fill.svg';
import CheckBox from '../../components/CheckBox';
import InfoToolTip from '@components/InfoToolTip';

// Utils
import { toTimeConversion } from '@utils/time';

const TableRow: React.FC<any> = ({ item }) => {
  const history = useHistory();

  const column = useMemo(() => {
    const { time, unit } = toTimeConversion(item.time_in_vr || 0);

    return [
      item.id,
      item.registration_date || `-`,
      item.last_activity || `-`,
      item.level || `-`,
      item.game_sessions_count || `-`,
      `${time} ${unit}.` || `-`,
      `-`,
      `-`,
      item.mobile_platform || `-`
    ];
  }, [item]);

  const handleCheckBoxClick = () => {
    console.log('fdf');
  };

  return (
    <Row>
      <Cell>
        <CheckBox onChange={handleCheckBoxClick} />
      </Cell>
      {column.map((item1: any, index: number) => {
        if (index === 3) {
          return (
            <Cell key={index}>
              {item1} <InfoToolTip data={item.neuro_scores} />
            </Cell>
          );
        }
        return <Cell key={index}>{item1}</Cell>;
      })}
      <Cell>
        <Arrow onClick={() => history.push(`/user/${item.id}`)}>
          <img src={ArrowRight} alt="arrow icon" />
        </Arrow>
      </Cell>
    </Row>
  );
};

export default TableRow;
