import React from 'react';
import { Redirect, Switch, Route } from 'react-router-dom';

// Store
import { useSession } from '@store';

// Views
import LogIn from '@views/Sign';
import Table from '@views/Table';
import User from '@views/User';
import Summary from '@views/Summary';

// Components
import Header from '@components/Header';
import WrapBG from '@assets/img/tableBg.png';

// Styles
import { GlobalStyle, Wrapper, Container } from '@styles/Global.styled';

const App: React.FC<{}> = () => {
  const { isAuthorized } = useSession();

  return (
    <div>
      <GlobalStyle />
      {isAuthorized ? (
        <Switch>
          <Wrapper bg={WrapBG}>
            <Container>
              <Header />
              <Redirect from="/sign" to="/" />
              <Route component={Summary} path="/summary" />
              <Route component={User} path="/user/:userId" />
              <Route exact component={Table} path="/" />
            </Container>
          </Wrapper>
        </Switch>
      ) : (
        <Switch>
          <Route component={LogIn} path="/sign" />
          <Redirect to="/sign" />
        </Switch>
      )}
    </div>
  );
};

export default App;
