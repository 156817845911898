import styled, { createGlobalStyle } from 'styled-components';

// Icons
import ArrowIcon from '../assets/img/icon/arrow-down.svg';

export const Wrapper = styled.div<{
  bg?: string;
  height?: number | string;
  padBot?: string | number;
}>`
  width: 100%;
  height: ${(props) => props.height};
  background-image: url(${(props) => props.bg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  position: relative;
  padding-bottom: ${(props) => props.padBot};
`;

export const Container = styled.div`
  max-width: 1360px;
  margin: 0 auto;
`;

export const Shadow = styled.div`
  position: absolute;
  width: 1360px;
  height: 216px;
  background: linear-gradient(
    180deg,
    rgba(52, 53, 56, 0.0001) 0%,
    #080808 100%
  );
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
`;

export const ArrowDown = styled.div`
  position: absolute;
  bottom: 18px;
  left: 50%;
  width: 32px;
  height: 32px;
  z-index: 1;
  transform: translateX(-50%);
  background-image: url(${ArrowIcon});
  background-position: center center;
  background-size: cover;
  border: none;
  cursor: pointer;
`;

export const Back = styled.button`
  width: 187px;
  height: 59px;
  background: #131315;
  backdrop-filter: blur(135.914px);
  border-radius: 29.5px;
  margin-left: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  text-transform: uppercase;
  color: #ffffff;
  mix-blend-mode: normal;
  opacity: 0.8;
`;

export const Exit = styled.span`
  display: block;
  font-weight: 500;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  line-height: 20px;
  text-decoration-line: underline;
  text-transform: uppercase;
  color: #ededed;
  mix-blend-mode: normal;
  opacity: 0.5;
  cursor: pointer;
`;

export const Inner = styled.div<{
  justify?: string;
  marginTop?: number | string;
}>`
  display: flex;
  justify-content: ${(props) => props.justify};
  align-items: center;
  margin-top: ${(props) => props.marginTop};
`;

export const MiniLogo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
    180deg,
    rgba(52, 53, 56, 0.1) 0%,
    rgba(52, 53, 56, 0.1) 100%
  );
  box-shadow: 0 2px 24px #000000;
  backdrop-filter: blur(27.1828px);
  width: 72px;
  height: 72px;
  border-radius: 8px;
`;

export const SummaryButton = styled.button`
  border: none;
  background: rgba(255, 255, 255, 0.1);
  mix-blend-mode: normal;
  backdrop-filter: blur(100px);
  border-radius: 29.5px;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.8);
  padding: 20px 34px 21px 35px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  margin-right: 32px;
  cursor: pointer;

  a {
    color: rgba(255, 255, 255, 0.8);
    text-decoration: none
  }
`;

export const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }
  body, h1, h2, h3, h4, h5, h6 {
    margin: 0;
    padding: 0;
  }
`;
