import styled from 'styled-components';

export const Label = styled.label`
  display: flex;
`;

export const Input = styled.input`
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  position: relative;
  height: 26px;
  width: 26px;
  border-radius: 6px;
  transition: all 0.15s ease-out 0s;
  background: transparent;
  border: 1px solid #14edfe;
  cursor: pointer;
  display: inline-block;
  margin-left: 31px;
  outline: none;
  position: relative;

  &:checked {
    background: #14edfe;
    box-shadow: 0px 2px 14px #14edfe;
  }
  &:checked::before {
    height: 26px;
    width: 26px;
    content: '';
    position: absolute;
    display: inline-block;
  }
  &:checked::after {
    -webkit-animation: click-wave 0.65s;
    -moz-animation: click-wave 0.65s;
    animation: click-wave 0.65s;
    background: #40e0d0;
    content: '';
    display: block;
    position: relative;
    z-index: 100;
  }
`;
