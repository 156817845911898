import { Action } from './reducer';
import { LOGIN, LOGOUT } from './types';

export const login = (): Action => ({
  type: LOGIN
});

export const logout = (): Action => ({
  type: LOGOUT
});
