import { useEffect, useCallback, RefObject } from 'react';

export const useOnClickOutside = (
  clickRef: RefObject<HTMLDivElement> | null,
  toggle: () => void
) => {
  const handleClickOutside = useCallback(
    (e: MouseEvent) => {
      if (
        clickRef?.current &&
        !clickRef?.current.contains(e.target as HTMLElement)
      )
        toggle?.();
    },
    [toggle]
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);
};
