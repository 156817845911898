import { combineReducers, compose, createStore, Store } from 'redux';

// Reducers
import session from './services/session';

const reducer = combineReducers({ session });

export default (): Store => createStore(reducer, compose());

export * from './services/session';
