import { LOGIN, LOGOUT } from './types';

export interface Action {
  type: typeof LOGIN | typeof LOGOUT;
}

const initialState: any = {
  isAuthorized: false
};

export default (state = initialState, action: Action): any => {
  switch (action.type) {
    case LOGIN:
      return { ...state, isAuthorized: true };
    case LOGOUT:
      return { ...state, isAuthorized: false };
    default:
      return state;
  }
};
