import { useMutation } from '@apollo/client';
import React, { useCallback } from 'react';

import { Form } from 'react-final-form';
import * as yup from 'yup';

// GrahpQL
import SIGN_IN from './graphql/signIn.gql';

// Containers
import SignForm from './containers/Form';

// Components
import { gqlErrorHandler, asyncValidate } from '@components/Form';

// Styles
import { Wrapper, Logo, Inner, Info, Span } from './Sign.styles';

// Store
import { useSession } from '@store';

// Icons
import LogoIcon from '@assets/img/full-logo.svg';
import BackGround from '@assets/img/img-bg.jpg';

const LogInPage: React.FC<{}> = () => {
  const { login } = useSession();
  const [signIn] = useMutation(SIGN_IN);

  const handleFormSubmit = useCallback(async (values) => {
    try {
      await signIn({ variables: values });
      login();
    } catch (error: any) {
      return gqlErrorHandler(error);
    }
  }, [signIn]);

  return (
    <Wrapper bg={BackGround}>
      <Logo src={LogoIcon} alt="Logo image" />
      <Form
        onSubmit={handleFormSubmit}
        render={SignForm as any}
        validate={asyncValidate(
          yup.object().shape({
            email: yup.string().email().required(),
            password: yup.string().required()
          })
        )}
      />
      <Inner>
        <Info>UPGRADE VR analytics</Info>
        <Span>V 0.1</Span>
      </Inner>
    </Wrapper>
  );
};

export default LogInPage;
