import React, { useState, useCallback } from 'react';

// Styled-component
import { Label, Input } from '../Common.styles';

interface checkBoxProps {
  checked?: boolean;
  onChange: (check: boolean) => void;
}

const CheckBox: React.FC<checkBoxProps> = ({ onChange, checked = false }) => {
  const [check, setCheck] = useState(checked);

  const handleClick = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setCheck(event.target.checked);
      onChange && onChange(event.target.checked);
    },
    [onChange]
  );

  return (
    <Label>
      <Input
        checked={check}
        type="checkbox"
        className="option-input checkbox"
        onChange={handleClick}
      />
    </Label>
  );
};

export default CheckBox;
