import styled from 'styled-components';

export const Point = styled.div`
  width: 7px;
  height: 7px;
  background-color: white;
  border-radius: 50px;
  margin-left: 10px;
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
`;

export const Label = styled.span`
  font-family: Roboto Mono;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  cursor: pointer;
  text-decoration: underline;
`;

export const Body = styled.div<{
  isOpen: boolean;
}>`
  position: absolute;
  width: 200px;
  background-color: black;
  border-top: 1px solid #e5e8ec;
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  border-radius: 8px;
  border: 1px solid rgba(20, 237, 254, 0.5);
  z-index: 1000;
  padding: 10px 0;
  top: 55px;
  left: -50px;
`;

export const HeaderItemLabel = styled.div`
  font-family: Roboto Mono;
  font-size: 13px;
  opacity: 0.5;
  padding: 0 24px;
  margin: 10px 0 5px 0;
`;

export const Item = styled.div`
  padding: 6px 24px;
  display: flex;
  align-items: center;
`;

export const ItemLabel = styled.span`
  cursor: pointer;
  font-family: Roboto Mono;
  font-size: 13px;

  &:hover {
    opacity: 0.5;
  }
`;
