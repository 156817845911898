import styled from 'styled-components';

export const Login = styled.button`
  width: 350px;
  height: 64px;
  background: linear-gradient(180deg, #98b6bc 0%, #596d70 100%),
    linear-gradient(180deg, #007f9b 0%, #14edfe 100%);
  background-blend-mode: multiply, normal;
  box-shadow: 0px 5px 17px rgba(0, 0, 0, 0.193216),
    inset 0px 1px 11px rgba(0, 0, 0, 0.06411);
  border-radius: 12px;
  border: none;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  color: #ffffff;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
`;

export const ContainerForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: -16px;
`;

export const Error = styled.span`
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 4px;
  text-transform: uppercase;
  color: #a8a8a8;
  margin-bottom: 12px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
`;
