import { ApolloError, ServerError } from '@apollo/client';
import { FORM_ERROR } from 'final-form';

export default (error: ApolloError): Record<string, string> => {
  const errors: Record<string, string> = {};

  if (error.networkError) {
    errors[FORM_ERROR] = (error.networkError as ServerError).result?.error;
  }

  return errors;
};
