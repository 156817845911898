import { useQuery } from '@apollo/client';

import React from 'react';

// GraphQL
import GET_DASH_BOARD from './graphql/getDashBoard.gql';

// Utils
import { toTimeConversion } from '@utils/time';

import {
  AllUsersContainer,
  Box,
  ActiveUser,
  ActiveUserScore,
  ActiveUserWrap,
  AllDownload,
  AllDownloadScore,
  AllUsers,
  AllUsersScore,
  SummaryAppInfo,
  SummaryAppInfoName,
  SummaryBlock,
  SummaryBlockTotal,
  SummaryScoreBlock,
  SummaryScoreBlockInfoWhite,
  SummaryScoreBlockText,
  SummaryTable,
  SummaryTableCall,
  SummaryTableLvl,
  SummaryTableRow,
  SummaryWrapper
} from './Summary.styled';

const TRAINING_TYPE: Record<any, string> = {
  spatial_orientation: 'Spatial orientation',
  synchro: 'Synchronisation',
  reaction: 'Reaction',
  focusing: 'Focusing',
  memory: 'Memory'
};

const SummaryPage: React.FC<{}> = () => {
  const { data, loading } = useQuery<{ dashboard: any }>(GET_DASH_BOARD);

  const { time, unit } = toTimeConversion(
    data?.dashboard?.average_session_time || 0
  );

  return (
    <SummaryWrapper height={loading}>
      {!loading && (
        <>
          <SummaryBlock>
            <SummaryBlockTotal>
              <AllUsersContainer>
                <AllUsers>
                  VR пользователи:
                  <AllUsersScore>{data?.dashboard?.vr_users}</AllUsersScore>
                </AllUsers>
                <Box>
                  <AllDownload>приложениe:</AllDownload>
                  <AllDownloadScore>
                    {data?.dashboard?.ios_users +
                      data?.dashboard?.android_users}
                  </AllDownloadScore>
                </Box>
              </AllUsersContainer>
              <AllDownload>Всего скачиваний моб. приложения</AllDownload>
              <AllDownloadScore>
                {data?.dashboard?.ios_users} / iOS /
                {(data?.dashboard?.ios_percent).toFixed(1)}%
              </AllDownloadScore>
              <AllDownloadScore>
                {data?.dashboard?.android_users}/ Android /
                {(data?.dashboard?.android_percent).toFixed(1)}%
              </AllDownloadScore>
              <ActiveUser>Активных пользователей моб.приложения</ActiveUser>
              <ActiveUserWrap>
                <ActiveUserScore>
                  {data?.dashboard?.active_users}
                </ActiveUserScore>
              </ActiveUserWrap>
            </SummaryBlockTotal>
            <SummaryScoreBlock>
              <SummaryScoreBlockText>Всего сессий:</SummaryScoreBlockText>
              <SummaryScoreBlockInfoWhite>
                {data?.dashboard?.total_sessions_count}
              </SummaryScoreBlockInfoWhite>
            </SummaryScoreBlock>
            <SummaryScoreBlock>
              <SummaryScoreBlockText>
                Среднее время пользователя в игре:
              </SummaryScoreBlockText>
              <SummaryScoreBlockInfoWhite>
                {time} {unit}.
              </SummaryScoreBlockInfoWhite>
            </SummaryScoreBlock>
            <SummaryScoreBlock>
              <SummaryScoreBlockText>
                Среднее количество пройденных тренингов у одного пользователя:
              </SummaryScoreBlockText>
              <SummaryScoreBlockInfoWhite>
                {data?.dashboard?.average_training_count}
              </SummaryScoreBlockInfoWhite>
            </SummaryScoreBlock>
          </SummaryBlock>
          <SummaryBlock>
            <SummaryTable>
              <SummaryTableRow>
                <SummaryTableCall>Название тренинга</SummaryTableCall>
                <SummaryTableCall>кол-во сессий</SummaryTableCall>
                <SummaryTableCall>
                  всего проведено времени в тренинге
                </SummaryTableCall>
              </SummaryTableRow>
              {data?.dashboard?.training_types.map(
                (training: any, i: number) => {
                  const { time, unit } = toTimeConversion(
                    training.total_training_time || 0
                  );

                  return (
                    <SummaryTableRow key={i}>
                      <SummaryTableCall>
                        {TRAINING_TYPE[training.name]}
                      </SummaryTableCall>
                      <SummaryTableCall>
                        {training.trainings_count}
                      </SummaryTableCall>
                      <SummaryTableCall>
                        {time} {unit}.
                      </SummaryTableCall>
                    </SummaryTableRow>
                  );
                }
              )}
            </SummaryTable>
            <SummaryTableLvl>
              <SummaryTableRow>
                <SummaryTableCall>Уровень</SummaryTableCall>
                <SummaryTableCall>кол-во очков</SummaryTableCall>
                <SummaryTableCall>всего пользователей</SummaryTableCall>
              </SummaryTableRow>
              {data?.dashboard?.level_statistic.map(
                (level: any, index: number) => (
                  <SummaryTableRow key={index}>
                    <SummaryTableCall>{level.level}</SummaryTableCall>
                    <SummaryTableCall>
                      {level.points_to_change}
                    </SummaryTableCall>
                    <SummaryTableCall>
                      {level.vr_profiles_count}
                    </SummaryTableCall>
                  </SummaryTableRow>
                )
              )}
            </SummaryTableLvl>
          </SummaryBlock>
          <SummaryBlock>
            <SummaryAppInfo>
              <SummaryAppInfoName target="_blank">
                ПРИЛОЖЕНИЕ APP STORE
              </SummaryAppInfoName>
              <SummaryAppInfoName
                target="_blank"
                href="https://play.google.com/store/apps/details?id=com.upgradevrappeng"
              >
                ПРИЛОЖЕНИЕ GOOGLE PLAY
              </SummaryAppInfoName>
              <SummaryAppInfoName target="_blank">
                СТРАНИЦА ИГРЫ В STEAM
              </SummaryAppInfoName>
            </SummaryAppInfo>
          </SummaryBlock>
        </>
      )}
    </SummaryWrapper>
  );
};

export default SummaryPage;
