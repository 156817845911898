import React from 'react';
import { useParams } from 'react-router-dom';

// Styles
import {
  Wrapper,
  ItemLvlBlock,
  ItemLvl,
  ItemLvlInner,
  Items,
  Lvl,
  LvlNum,
  User,
  UserId,
  ItemLvlInfo,
  ItemLvlNum,
  ItemLvlInfoBox,
  ItemInfoBlock,
  ItemsInner,
  InnerLeftSide,
  ItemInfoBlockScore,
  ItemInfoBlockScoreNum,
  Attainment,
  AttainmentTitle,
  AchievedTitle,
  AchievedList,
  AchievedItem
} from './UserPage.style';

// Icons
import spatialImg from '@assets/img/icon/spatial.png';
import handEyeImg from '@assets/img/icon/handEye.png';
import attentionImg from '@assets/img/icon/attention.png';
import memoryImg from '@assets/img/icon/memory.png';

// Hooks
import { useUserPage } from './UserPage.hooks';

const UserPage: React.FC<{}> = () => {
  const { userId } = useParams<{ userId: string }>();

  const { userData, loading } = useUserPage(userId);

  return (
    <Wrapper height={!loading}>
      {!loading && (
        <>
          <User>Пользователь:</User>
          <UserId>id{userData.id}</UserId>
          <Items>
            <InnerLeftSide>
              <ItemLvlBlock>
                <Lvl>
                  LVL: <LvlNum>{userData.level}</LvlNum>
                </Lvl>
                <ItemLvlInner>
                  <ItemLvl>
                    <img src={spatialImg} alt="" />
                    <ItemLvlInfoBox>
                      <ItemLvlInfo color="#4EFF60">
                        Spatial abilitieS
                      </ItemLvlInfo>
                      <ItemLvlNum color="#4EFF60">
                        {userData.spatial_abilities || 0}
                      </ItemLvlNum>
                    </ItemLvlInfoBox>
                  </ItemLvl>
                  <ItemLvl>
                    <img src={handEyeImg} alt="" />
                    <ItemLvlInfoBox>
                      <ItemLvlInfo color="#FDC70D">
                        HAND-EYE COORDINATION
                      </ItemLvlInfo>
                      <ItemLvlNum color="#FDC70D">
                        {userData.hand_eye_coordination || 0}
                      </ItemLvlNum>
                    </ItemLvlInfoBox>
                  </ItemLvl>
                  <ItemLvl>
                    <img src={attentionImg} alt="" />
                    <ItemLvlInfoBox>
                      <ItemLvlInfo color="#AE7FFF">
                        ATTENTION EFFICIENCY
                      </ItemLvlInfo>
                      <ItemLvlNum color="#AE7FFF">
                        {userData.attention_efficiency || 0}
                      </ItemLvlNum>
                    </ItemLvlInfoBox>
                  </ItemLvl>
                  <ItemLvl>
                    <img src={memoryImg} alt="" />
                    <ItemLvlInfoBox>
                      <ItemLvlInfo color="#FB6E41">WORKING MEMORY</ItemLvlInfo>
                      <ItemLvlNum color="#FB6E41">
                        {userData.working_memory || 0}
                      </ItemLvlNum>
                    </ItemLvlInfoBox>
                  </ItemLvl>
                </ItemLvlInner>
              </ItemLvlBlock>
              <ItemsInner>
                {userData.trainings_stat.map(
                  (
                    {
                      name,
                      trainings_count,
                      total_time,
                      difficulty_level
                    }: any,
                    index: number
                  ) => (
                    <ItemInfoBlock key={index + `-${name}`}>
                      <ItemInfoBlockScore>{name}</ItemInfoBlockScore>
                      <ItemInfoBlockScore>
                        Сессий:
                        <ItemInfoBlockScoreNum>
                          {trainings_count}
                        </ItemInfoBlockScoreNum>
                      </ItemInfoBlockScore>
                      <ItemInfoBlockScore>
                        Суммарное время:
                        <ItemInfoBlockScoreNum>
                          {total_time}
                        </ItemInfoBlockScoreNum>
                      </ItemInfoBlockScore>
                      <ItemInfoBlockScore>
                        Уровень сложности:
                        <ItemInfoBlockScoreNum>
                          {difficulty_level || 0}
                        </ItemInfoBlockScoreNum>
                      </ItemInfoBlockScore>
                    </ItemInfoBlock>
                  )
                )}
              </ItemsInner>
            </InnerLeftSide>
            {userData.achievements.length > 0 && (
              <Attainment>
                <AttainmentTitle>ДОСТИЖЕНИЯ</AttainmentTitle>
                <AchievedTitle>ПОЛУЧЕНО</AchievedTitle>
                <AchievedList>
                  {userData.achievements.map((item: string, index: number) => (
                    <AchievedItem key={index}>{item}</AchievedItem>
                  ))}
                </AchievedList>
              </Attainment>
            )}
          </Items>
        </>
      )}
    </Wrapper>
  );
};

export default UserPage;
