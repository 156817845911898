// Hooks
import React, { useState, useRef, useCallback, useMemo } from 'react';

// Utils
import { useOnClickOutside } from '@utils/useOnClickOutside';

// Styles
import {
  Container,
  Label,
  Body,
  Item,
  ItemLabel,
  HeaderItemLabel,
  Point
} from './Dropdown.styles';

interface DropdownItem {
  id: number;
  label: string;
  value: string;
}

interface DropdownProps {
  children: React.ReactNode;
  list?: Array<DropdownItem>;
  handler: any;
}

const Dropdown: React.FC<DropdownProps> = ({
  children,
  list = [],
  handler
}) => {
  const [isOpen, setOpen] = useState<boolean>(false);
  const [selectedItems, setSelectedItems] = useState<Array<number>>([]);
  const ref = useRef<HTMLDivElement>(null);

  useMemo(() => {
    const arr = list.filter(({ id }) => selectedItems.includes(id));
    handler(Array.from(arr, (item) => item.value));
  }, [selectedItems, handler]);

  useOnClickOutside(ref, () => setOpen(false));

  const toggleDropdown = () => {
    list.length && setOpen(!isOpen);
  };

  const handleItemClick = useCallback(
    (id: number) => {
      selectedItems.includes(id)
        ? setSelectedItems(selectedItems.filter((i) => i !== id))
        : setSelectedItems([...selectedItems, id]);
    },
    [selectedItems, setSelectedItems]
  );

  return (
    <Container>
      <Label onClick={toggleDropdown}>{children}</Label>
      <Body isOpen={isOpen}>
        {list.length && (
          <div ref={ref}>
            <HeaderItemLabel>Фильтрация по:</HeaderItemLabel>

            {list.map((item, index: number) => {
              return (
                <Item
                  key={`${item.label + index}`}
                  onClick={() => handleItemClick(item.id)}
                >
                  <ItemLabel>{item.label}</ItemLabel>
                  {selectedItems.includes(item.id) && <Point />}
                </Item>
              );
            })}
          </div>
        )}
      </Body>
    </Container>
  );
};

export default Dropdown;
