import React from 'react';
import { Field, FieldProps } from 'react-final-form';

// Styled-components
import { Input } from './Common.styles';

const FormInput: React.FC<FieldProps<string, any>> = ({
  name,
  type,
  placeholder
}) => (
  <Field name={name}>
    {({ input }) => (
      <Input
        name={input.name}
        type={type}
        onChange={input.onChange}
        onBlur={input.onBlur}
        value={input.value}
        placeholder={placeholder}
      />
    )}
  </Field>
);

export default FormInput;
