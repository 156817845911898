import styled from 'styled-components';

export const Wrapper = styled.div<{ bg: string }>`
  width: 100%;
  height: 100vh;
  color: white;
  background-image: url(${(props) => props.bg});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

export const Logo = styled.img`
  padding-top: 64px;
`;

export const Info = styled.p`
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 4px;
  text-transform: uppercase;
  color: #a8a8a8;
  margin-bottom: 12px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
`;

export const Inner = styled.div`
  padding-bottom: 45px;
`;

export const Span = styled.span`
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #fff;
  opacity: 0.2;
  text-align: center;
  display: block;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
`;
