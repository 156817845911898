import styled from 'styled-components';

export const Input = styled.input`
  width: 350px;
  height: 56px;
  background: #0a0a0a;
  box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.220599), inset 0px 0px 6px #171717;
  border-radius: 10px;
  border: none;
  padding: 0 25px;
  outline: none;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: rgba(255, 255, 255, 0.5);
  font-family: 'Roboto Mono', monospace;
  font-weight: 400;
  margin-bottom: 15px; 
`;
