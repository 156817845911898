// Hooks
import { useQuery } from '@apollo/client';

// GraphQL
import GET_USERS from './graphql/getUsers.gql';

// Interfaces
interface GetUsersDataPagy {
  count: number;
  page: number;
}

interface GetUsersDataItem {
  id: number | string;
  registration_date: string;
  last_activity: string;
  level: number;
  neuro_scores: any;
  game_sessions_count: number;
  trainings_count: number;
  time_in_vr: number;
  mobile_platform: string;
  vr_platform: string;
  vr_headset_model: string;
}

interface GetUsersData {
  listUsers: {
    pagy: GetUsersDataPagy;
    users: Array<GetUsersDataItem>;
  };
}

const useTable = (page: number, sort: 'asc' | 'desc', formattedURL: string) => {
  const { data, loading } = useQuery<GetUsersData, any>(GET_USERS, {
    variables: {
      page,
      sort,
      formattedURL
    }
  });

  return { data, loading };
};

export default useTable;
