import React from 'react';
import { FormRenderProps } from 'react-final-form';

// Components
import { Input } from '@components/Form';
import { Alert, AlertTitle } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';

// Styled-component
import { Login, ContainerForm } from './Form.styles';

const useStyles = makeStyles({
  error: {
    marginTop: 25
  }
});

const SignForm: React.FC<FormRenderProps> = ({ handleSubmit, submitError }) => {
  const classes = useStyles();

  return (
    <ContainerForm onSubmit={handleSubmit}>
      <Input name="email" type="text" placeholder="Email" />
      <Input name="password" type="password" placeholder="Password" />
      <Login onClick={handleSubmit}>Log In</Login>
      {submitError && (
        <Alert className={classes.error} severity="error">
          <AlertTitle>Error!</AlertTitle>
          {submitError}
        </Alert>
      )}
    </ContainerForm>
  );
};

export default SignForm;
