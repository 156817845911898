import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  InMemoryCache,
  ServerError
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { RestLink } from 'apollo-link-rest';

import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import ReactDOM from 'react-dom';

// App
import App from './App';

// Store
import createStore, { logout } from '@store';

const store = createStore();

const errorLink = onError(({ networkError }) => {
  switch ((networkError as ServerError).statusCode) {
    case 401:
      store.dispatch(logout());
      break;
  }
});

const restLink = new RestLink({
  credentials: 'include',
  endpoints: {
    v1: 'https://upgradevr-api.flowvr.ru/api/v1'
  }
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  connectToDevTools: true,
  link: ApolloLink.from([errorLink, restLink])
});

const Main = () => (
  <React.StrictMode>
    <ApolloProvider client={client}>
      <Provider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
    </ApolloProvider>
  </React.StrictMode>
);

ReactDOM.render(<Main />, document.getElementById('root'));
