// Hooks
import React, { useState, useEffect } from 'react';

// CSV
import { CSVLink } from 'react-csv';
import * as CSV from 'csv-string';

// Styles
import { SummaryButton } from '@styles/Global.styled';

const ButtonDownload: React.FC<{}> = () => {
  const [fileData, setFileData] = useState<Array<Array<string>> | string>('');

  useEffect(() => {
    async function getReports() {
      const response = await fetch(
        'https://upgradevr-api.flowvr.ru/api/v1/admins/reports',
        {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/csv'
          },
          credentials: 'include'
        }
      );

      const data = await response.text();
      const arr = CSV.parse(data);
      setFileData(arr);
    }
    getReports();
  }, []);

  return (
    <div>
      <SummaryButton>
        <CSVLink data={fileData} filename="reports.csv" target="_blank">
          СКАЧАТЬ ОТЧЕТ
        </CSVLink>
      </SummaryButton>
    </div>
  );
};

export default ButtonDownload;
