import React from 'react';
import Select, { StylesConfig } from 'react-select';

const customStyles: StylesConfig<any, any> = {
  singleValue: (provider) => ({
    ...provider,
    opacity: 0.8,
    color: '#FFFFFF',
    paddingLeft: 10
  }),
  placeholder: (provider) => ({
    ...provider,
    opacity: 0.8,
    color: '#FFFFFF',
    paddingLeft: 10
  }),
  control: (provided) => ({
    ...provided,
    fontFamily: 'Roboto Mono',
    marginBottom: 10,
    width: 300,
    height: 60,
    border: 0,
    borderRadius: 8,
    background: 'rgba(25, 25, 25)',
    boxShadow: 'none'
  }),
  indicatorSeparator: (provider) => ({
    ...provider,
    display: 'none'
  }),
  menu: (provided) => ({
    ...provided,
    width: 300,
    borderRadius: 8,
    background: 'rgba(25, 25, 25)',
    zIndex: 1,
    paddingLeft: 10
  }),
  option: (provider) => ({
    ...provider,
    fontFamily: 'Roboto Mono',
    fontSize: 16,
    opacity: 0.8,
    height: 60,
    color: '#FFFFFF',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'transparent'
  })
};

interface SelectComponentProps {
  onChange: any;
  options: any;
}

const SelectComponent: React.FC<SelectComponentProps> = ({
  onChange,
  options
}) => (
  <Select
    defaultValue={options[1]}
    styles={customStyles}
    onChange={onChange}
    options={options}
    isSearchable={false}
  />
);

export default SelectComponent;
