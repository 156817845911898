// Hooks
import React, { useCallback, useState, useEffect, useMemo } from 'react';
import useTable from './Table.hooks';

// Containers
import TableRow from './containers/TableRow';

// Components
import CheckBox from './components/CheckBox';
import Dropdown from '@components/Dropdown';
import Select from '@components/Select';
import ButtonDownload from './components/ButtonDownload';
import ReactPaginate from 'react-paginate';
import { Oval } from 'react-loader-spinner';

// Styles
import {
  ActionTable,
  BoardWrapper,
  Row,
  Cell,
  LoaderContainer,
  ContainerTable,
  TextNoData
} from './Tabel.styles';

// Styles
import styles from './Table.scss';

// Data
import { DATA_CELL_TABLE } from './Table.data';

interface SortItem {
  value: 'asc' | 'desc';
  label: 'По убыванию' | 'По возрастанию';
}

const options = [
  { value: 'desc', label: 'По убыванию' },
  { value: 'asc', label: 'По возрастанию' }
] as Array<SortItem>;

const Table: React.FC<{}> = () => {
  // Setup
  const [users, setUsers] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [sort, setSorting] = useState<'asc' | 'desc'>('asc');
  const [deviceOS, setdeviceOS] = useState<Array<string>>([]);
  const [vrProfile, setvrProfile] = useState<Array<string>>([]);
  const [vrHeadset, setvrHeadset] = useState<Array<string>>([]);
  const [formattedURL, setFormattedURL] = useState<string>('');
  const usersPerPage = 10;
  const pageCount = Math.ceil(users / usersPerPage);

  const { data, loading } = useTable(page, sort, formattedURL);

  // Effects
  useEffect(() => {
    data && setUsers(data?.listUsers?.pagy?.count);
  }, [data]);

  useMemo(() => {
    let stringDeviceOS_URL = '';
    let stringVRProfile_URL = '';
    if (deviceOS.length > 0) {
      deviceOS.forEach((item) => {
        stringDeviceOS_URL += `&q[device_os_in][]=${item}`;
      });
    } else {
      stringDeviceOS_URL = '';
    }

    if (vrProfile.length > 0) {
      vrProfile.forEach((item) => {
        stringVRProfile_URL += `&q[vr_profile_registered_through_in][]=${item}`;
      });
    } else {
      stringVRProfile_URL = '';
    }

    setFormattedURL(stringDeviceOS_URL + stringVRProfile_URL);
  }, [deviceOS, vrProfile, vrHeadset]);

  const handleAllRowClick = useCallback((check: boolean) => {
    console.log('check ------->', check);
  }, []);

  const handleSortClick = useCallback(({ value }) => setSorting(value), [sort]);

  const handleChangePage = useCallback(({ selected }: any) => {
    setPage(selected + 1);
  }, []);

  return (
    <>
      <BoardWrapper height={loading}>
        <ContainerTable>
          <ActionTable>
            <Select options={options} onChange={handleSortClick} />
            <ButtonDownload />
          </ActionTable>
          <Row>
            <Cell>
              <CheckBox onChange={handleAllRowClick} />
            </Cell>
            {DATA_CELL_TABLE.map((item, index) => (
              <Cell key={`${'DATA_CELL_TABLE' + index}`}>{item}</Cell>
            ))}
            <Dropdown
              list={[
                { id: 0, label: 'Guest', value: 'guest' },
                { id: 1, label: 'Steam', value: 'steam' },
                { id: 2, label: 'Oculus', value: 'oculus' },
                { id: 3, label: 'Mobile App', value: 'mobile_app' },
                { id: 4, label: 'Vr app', value: 'vr_app' }
              ]}
              handler={setvrProfile}
            >
              VR Платформа
            </Dropdown>
            <Dropdown list={[]} handler={setvrHeadset}>
              VR Устройство
            </Dropdown>
            <Dropdown
              list={[
                { id: 0, label: 'IOS', value: 'ios' },
                { id: 1, label: 'Android', value: 'android' }
              ]}
              handler={setdeviceOS}
            >
              Мобильное приложение
            </Dropdown>
          </Row>
          {loading ? (
            <LoaderContainer>
              <Oval width={50} />
            </LoaderContainer>
          ) : !!data?.listUsers?.users.length ? (
            data?.listUsers?.users?.map((item: any) => (
              <TableRow selected={item.active} key={item.id} item={item} />
            ))
          ) : (
            <LoaderContainer>
              <TextNoData>No data</TextNoData>
            </LoaderContainer>
          )}
          <ReactPaginate
            pageCount={pageCount}
            onPageChange={handleChangePage}
            containerClassName={styles.paginationBttns}
            previousLinkClassName={styles.previousBttn}
            nextLinkClassName={styles.nextBttn}
            disabledClassName={styles.paginationDisabled}
            activeClassName={styles.paginationActive}
            pageRangeDisplayed={5}
            marginPagesDisplayed={5}
          />
        </ContainerTable>
      </BoardWrapper>
    </>
  );
};

export default Table;
