import { useMutation, useApolloClient } from '@apollo/client';

import React, { useCallback } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

// Components
import { gqlErrorHandler } from '@components/Form';

// Styles-component
import { Root } from './Header.styles';
import {
  Back,
  Exit,
  Inner,
  MiniLogo,
  SummaryButton
} from '@styles/Global.styled';

// Icons
import LogoMini from 'assets/img/mini-logo.svg';

// GraphQL
import SIGN_OUT from './graphql/signOut.gql';

// Redux
import { useSession } from 'store';

const Header: React.FC<{}> = () => {
  const location = useLocation();
  const history = useHistory();
  const [signOut] = useMutation(SIGN_OUT);
  const client = useApolloClient();
  const { logout } = useSession();

  const handleGoBackClick = useCallback(() => {
    history.goBack();
  }, []);

  const handleLogOutClick = useCallback(async () => {
    try {
      await client.resetStore();
      await signOut();
      logout();
    } catch (error: any) {
      gqlErrorHandler(error);
    }
  }, []);

  return (
    <Root>
      <Inner justify={'center'}>
        <MiniLogo>
          <img src={LogoMini} alt="logo icon" />
        </MiniLogo>
        {location.pathname !== '/' && (
          <Back onClick={handleGoBackClick}>Вернуться Назад</Back>
        )}
      </Inner>
      <Inner justify={'center'}>
        <SummaryButton onClick={() => history.push('/summary')}>
          Сводная Статистика
        </SummaryButton>
        <Exit onClick={handleLogOutClick}>Выйти</Exit>
      </Inner>
    </Root>
  );
};

export default Header;
