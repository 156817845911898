import { useQuery } from '@apollo/client';

// GraphQL
import GET_USER from './graphql/getUser.gql';

// Utils
import { toTimeConversion } from '@utils/time';

const TRAINING_TYPE: Record<any, string> = {
  spatial_orientation: 'Coordination',
  memory: 'Memory',
  reaction: 'Reaction',
  focusing: 'Concentration',
  synchro: 'Synchronisation'
};

const useUserPage = (userId: string) => {
  const { data, loading } = useQuery(GET_USER, { variables: { userId } });
  let userData: any = {};

  if (!loading) {
    const {
      id,
      level,
      hand_eye_coordination,
      spatial_abilities,
      attention_efficiency,
      working_memory,
      trainings_stat,
      achievements
    } = data?.user;
    const newTrainings_stat: any = [];

    trainings_stat.forEach((obj: any) => {
      const newName = TRAINING_TYPE[obj.name];
      const { time, unit } = toTimeConversion(obj.total_time || 0);
      newTrainings_stat.push({
        ...obj,
        name: newName,
        total_time: `${time + ' ' + unit}`
      });
    });

    userData = {
      id,
      level,
      hand_eye_coordination,
      spatial_abilities,
      attention_efficiency,
      working_memory,
      trainings_stat: newTrainings_stat,
      achievements
    };
  }

  return { loading, userData };
};

export { useUserPage };
