export const toTimeConversion = (trainingTime: number) => {
    const hours = Math.floor(trainingTime / (60 * 60));
  
    if (hours) return { time: hours, unit: 'Час' };
  
    const divisorForMinutes = trainingTime % (60 * 60);
    const mitutes = Math.floor(divisorForMinutes / 60);
  
    if (mitutes) return { time: mitutes, unit: 'Мин' };
    const divisorForSeconds = divisorForMinutes % 60;
  
    const seconds = Math.ceil(divisorForSeconds);
    if (seconds) return { time: seconds, unit: 'Сек' };
  
    return { time: 0, unit: '' };
  };
  